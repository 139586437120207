import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"
import BooksTable from "../../components/library/BooksTable"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/library.css"
import JSONData from "../../../content/library/data.json"


function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/facilities/*.*" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        },
			allAllbooksCsv (sort: {fields: TITLE, order: ASC}){
			    nodes {
			      id
			      AUTHOR
			      ISBN
			      TITLE
			      CALL_NUMBER
			      DATE_ENTERED
			    }
			  }
			}
	`


	const pageBlockDatas = [

		]

	const pageBlocks = pageBlockDatas.map(
    (pageBlockData, key) => 
      <PageBlock key={key} childClass="eri" element={pageBlockData.element} header={pageBlockData.header} />
    )	

	let data = useStaticQuery(query)


	let images = data.allFile

	
	const orderNodes = data.allAllbooksCsv.nodes;


	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content ministries-colors library">
			
				<div>
					<Subheader data={JSONData} />
				</div>
				<iframe className="library-iframe" src="https://eagleriverinstitute.org/stjohns/library/"></iframe>	

			</main>
			<Footer />		
		</div>
		)
}

export default ERI 
//				<iframe className="library-iframe" src="https://stjohnalaskalibrary.org/"></iframe>	

//				<iframe className="library-iframe" src="http://157.245.226.253:9000/"></iframe>	
