import React, { Component } from "react"
import "../../styles/library.css"

class Donate extends Component {
	constructor(props){
		super(props)
		this.state= {
			titleQuery:'',
			authorQuery:''
		}
	}

	updateAuthorSearch = (value) => {
			console.log("fired")
			this.setState({authorQuery:value.toLowerCase()})

	}
	updateTitleSearch = (value) => {
			console.log("fired")
			this.setState({titleQuery:value.toLowerCase()})

	}
	wrapUpdateTitleSearch = (value) => {
		clearTimeout(this.state.wto);
		const wto = setTimeout(this.updateTitleSearch,300, value)
		this.setState({wto:wto})

	}
	wrapUpdateAuthorSearch = (value) =>{
		clearTimeout(this.state.wto);
		const wto = setTimeout(this.updateAuthorSearch,300, value)
		this.setState({wto:wto})

	}

	getRowFromNode = (node) =>{
		return (
	            <tr key={node.id} 
	            className={(node.TITLE.toLowerCase().includes(this.state.titleQuery) && node.AUTHOR.toLowerCase().includes(this.state.authorQuery)) ? '':'hidden'}>
	              <td>{node.TITLE}</td>
	              <td>{node.AUTHOR}</td>
	              <td>{node.ISBN}</td>
	              <td>{node.CALL_NUMBER}</td>
   	              <td>{node.DATE_ENTERED}</td>

	            </tr>
	          )
	}


	render () {

		let rows = this.props.orderNodes.map(node => this.getRowFromNode(node))
		return (
			<div className="booklist-wrapper">
			<div className="library-search-wrapper">
			<h3>Filter By</h3>

			<div className='library-search'>
				<label htmlFor="title-search">Title</label>
				<input  name="title-search" type="text" onChange={(event) => this.wrapUpdateTitleSearch(event.target.value)}/>
				<label htmlFor="author-search">Author</label>
				<input  name="auhor-search" type="text" onChange={(event) => this.wrapUpdateAuthorSearch(event.target.value)}/>
			</div>
			</div>
			<div>
			<table className='booklist'>
					        <thead>
					          <tr>
					            <th className='title-row'>Title</th>
					            <th className='author-row'>Author</th>
					            <th className='isbn-row'>ISBN</th>
					            <th className='cn-row'>Call Number</th>
					            <th className='da-row'>Date Added</th>

					          </tr>
					        </thead>
					        <tbody>
					          {rows}
					        </tbody>
					    </table>
					    </div>
					    </div>
		)
	}
	
}

export default Donate 